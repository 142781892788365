<template lang='pug'>
label
  slot
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
label {
  @apply text-sm;
}
</style>