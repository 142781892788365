<template lang="pug">
formTitle Actividad física
.form 
  .form-group
    FormLabel Selecciona tu género*
    .flex.gap-4.mt-1
      .button(
        @click="setGender('female')",
        :class="{ select: selectedGender == 'female' }"
      ) 
        svgIcon.icon(:path="mdiGenderFemale", :size="22", type="mdi")
        | Mujer
      .button(
        @click="setGender('male')",
        :class="{ select: selectedGender == 'male' }"
      ) 
        svgIcon.icon(:path="mdiGenderMale", :size="20", type="mdi")
        | Hombre
  .form-group.mt-4
    FormLabel Selecciona tu nivel de actividad*
    .flex.flex-col.gap-4.mt-1
      .option(
        :class="{ select: selectedActivity == 'sedentary' }",
        @click="setActivity('sedentary')"
      ) Casi ninguna actividad física
      .option(
        :class="{ select: selectedActivity == 'light' }",
        @click="setActivity('light')"
      ) A menudo salgo a caminar
      .option(
        :class="{ select: selectedActivity == 'moderate' }",
        @click="setActivity('moderate')"
      ) Realizo ejercicio de 1 a 2 veces por semana
      .option(
        :class="{ select: selectedActivity == 'active' }",
        @click="setActivity('active')"
      ) Realizo ejercicio de 3 a 5 veces por semana
      .option(
        :class="{ select: selectedActivity == 'extreme' }",
        @click="setActivity('extreme')"
      ) Realizo ejercicio de 6 a 7 veces por semana
</template>

<script>
import { mdiGenderFemale, mdiGenderMale } from "@mdi/js";
import FormLabel from "@/components/wizard/form-label.wizard";

export default {
  components: {
    FormLabel,
  },
  data: () => ({
    mdiGenderFemale,
    mdiGenderMale,
  }),
  methods: {
    setGender(gender) {
      this.$store.dispatch("analysisStore/setGender", gender);
      this.validateStore();
    },
    setActivity(activity) {
      this.$store.dispatch("analysisStore/setActivity", activity);
      this.validateStore();
    },
    validateStore() {
      if (this.selectedGender && this.selectedActivity) {
        setTimeout(() => {
          this.$router.push({ name: "Quiz_2" });
        }, 250);
      }
    },
  },
  computed: {
    selectedGender() {
      return this.$store.getters["analysisStore/getGender"];
    },
    selectedActivity() {
      return this.$store.getters["analysisStore/getActivity"];
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  @apply py-4;
}

.button {
  @apply w-full text-center py-2 rounded-lg font-medium border cursor-pointer text-gray-400 flex items-center justify-center relative transition-colors;

  &:hover {
    @apply bg-green-50 text-green-700 font-medium;
  }

  &.select {
    @apply text-white border-green-500 bg-green-500;
  }

  .icon {
    position: absolute;
    margin-left: 0.5rem;
    opacity: 0.8;
    left: 0;
  }
}

.option {
  @apply w-full text-center py-2 px-2 rounded-lg font-normal text-sm border cursor-pointer text-gray-400 bg-white;

  &:hover {
    @apply bg-green-50 text-green-700 font-medium border-green-200;
  }

  &.select {
    @apply text-white border-green-500 font-normal bg-green-500;
  }
}
</style>